<template>
	<div>
		<a-modal title="确认预约-选择服务人员" :width="480" :visible="visible" @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div class="flex center">
						<a-input-search placeholder="搜索服务人员" v-model="search.name" style="width: 420px" @search="onSearch" />
					</div>

					<div class="mt20">
						<div class="flex wrap center" v-if="datas.length>0">
							<div class="choose-serviceman-item flex space" v-for="(item,index) in datas">
								<div class="flex">
									<img  :src="item.face"/>
									<div class="ml10">
										<div class="ft16 ftw600 cl-main text-over4">{{item.name}}</div>
										<div class="mt15 flex alcenter">
											<i class="iconfont iconcall_gray mr5 ft12 cl-notice"/>
											<div class="cl-info ft12 ftw400 text-over4">{{item.mobile}}</div>
										</div>
									</div>
								</div>
								<div class="flex alcenter center" >
									<div class="choose-serviceman-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
										确认预约
									</div>
								</div>
							</div>
						</div>

						<div v-else class="flex center mt20">
							<a-empty/>
						</div>
					</div>

					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../../common/mixin/list.js';
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			appointment_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data() {
			return {
				loading: false,
				confirmLoading: false,
				pagination:{
					total:0,
					pageSize:4,
					current:1,
				},
				search:{
					name:'',
				},
				datas:[],

			}
		},

		methods: {

			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/distributionServicemanFromAppointment',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			onSearch(value){
				this.page=1;
				this.getLists()
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			checkIt(index){
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('platform/distributionAppointment',{
					appointment_id:this.appointment_id,
					serviceman_id:this.datas[index].serviceman_id,
				}).then(res=>{
					this.$message.success('分配成功',1,()=>{
						this.loading=false;
						this.$emit('ok');
					})

				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			}
		}
	}
</script>

<style>
	.choose-serviceman-item{
		width: 420px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		margin-bottom: 10px;
		padding: 10px;
	}

	.choose-serviceman-item img{
		width: 60px;
		height: 60px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		border-radius: 40px;
		border: 2px solid #FFFFFF;
	}

	.choose-serviceman-item-duihao{
		width: 60px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
	}
	.choose-serviceman-item-select{
		width: 80px;
		height: 32px;
		background: #4772FF;
		border-radius: 4px;
		cursor: pointer;
	}
</style>
