<template>
	<div>
		<a-modal title="选择项目规格" :width="480" :visible="visible" @cancel="handleCancel" >
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="choose-sku-project flex" >
						<div class="flex">
							<img v-if="project.cover_img != null" :src="project.cover_img"/>
							<div class="ml10">
								<div class="ft14 ftw400 cl-black text-over4">{{project.name}}</div>
								<div class="ft12 ftw400 cl-theme mt5 " v-if="form.sku_index != -1">会员价格：¥{{project.skus[form.sku_index].price}}</div>
								<div class="ft12 ftw400 cl-theme mt5 " v-else>会员价格：¥{{project.price}}</div>
								<div class="flex alcenter ft12 ftw400 cl-notice">
									<div>门市原价：</div>
									<div style="text-decoration: line-through;" v-if="form.sku_index != -1">¥{{project.skus[form.sku_index].market_price}}</div>
									<div style="text-decoration: line-through;" v-else>¥{{project.market_price}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt24" v-if="project.is_have_sku == 1">
						<div class="ft14 ftw500 cl-info">选择规格</div>
						<div class="mt16">
							<div class="flex wrap">
								<template v-for="(item,index) in project.skus">
									<div class="choose-sku-item active" v-if="index==form.sku_index">{{item.name}}</div>
									<div class="choose-sku-item " v-else @click="chooseSkuAct(index)">{{item.name}}</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			value:{
				type:Object,
				default:function(){
					return new Object
				}
			},
			project:{
				type:Object,
				default:function(){
					return new Object
				}
			},
		},
	
		data() {
			return {
				loading: false,
				confirmLoading: false,
				select_ids_data:[],
				form:{
					sku_index:-1,
				}
			}
		},
		
		created() {
			if(JSON.stringify(this.value) !== '{}' && this.value.project_id==this.project.project_id){
				for(var i in this.project.skus){
					if(this.value.sku_id==this.project.skus[i].project_sku_id){
						this.form.sku_index=i;
					}
				}
			}
		},
		
		methods: {
			chooseSkuAct(index){
				this.form.sku_index=index;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			onSubmit(){
				if(this.form.sku_index<0){
					this.$message.error('请选择规格');
					return ;
				}
				this.project.sku_id=this.project.skus[this.form.sku_index].project_sku_id;
				this.$emit("ok",this.project);
			}
		}
	}
</script>

<style>
	.choose-sku-project{
		width: 420px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
	}
	
	.choose-sku-project img{
		width: 60px;
		height: 60px;
	}
	
	.choose-sku-item{
		padding: 8px 12px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		font-size: 12px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
		margin-bottom: 10px;
		cursor: pointer;
	}
	
	.choose-sku-item.active{
		padding: 8px 12px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	
</style>
